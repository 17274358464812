import { Link } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import PortfolioItems from "../components/portfolio-items"
import "../components/custom.scss"
import SEO from "../components/seo"
import PortfolioArrow from "-!svg-react-loader?props[]=className:logo!../images/portfolio_arrow.svg";
import NCFlag from "../images/flags/nc-flag.png";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="home">
      <div className="heading">
        <h1>Hi there, I’m Iran - front-end developer and Shopify expert.</h1>
        <p>With over 10 years in the industry, I enjoy coding to solve problems, help products sell, and bring brands into the online world. I’m constantly learning new skills to create interactive and intuitive experiences that people love.</p>
        <p className="location">Currently based in North Carolina <img src={NCFlag} /></p>
      </div>
      <div className="latest-work">
        <div className="lw-heading lw-desktop">
          <p>Latest Work</p>
          {/*
          <Link to="/portfolio">
            View More <PortfolioArrow />
          </Link>
          */}
        </div>
        <PortfolioItems />
        {/*<div className="lw-heading lw-mobile">
          <Link to="/portfolio">
            View More <PortfolioArrow />
          </Link>
        </div>*/}
      </div>
    </div>
  </Layout>
)

export default IndexPage
