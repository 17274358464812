import { Link } from "gatsby"
import React from "react"
//import Placeholder from "../images/placeholder.jpg";
import ExternalIcon from "-!svg-react-loader?props[]=className:shopify!../images/svg/external.svg";
import ShopifyLogo from "-!svg-react-loader?props[]=className:shopify!../images/svg/shopify.svg";
//import WordpressLogo from "-!svg-react-loader?props[]=className:wordpress!../images/svg/wordpress.svg";
//import ReactLogo from "-!svg-react-loader?props[]=className:react!../images/svg/react.svg";
//import GatsbyLogo from "-!svg-react-loader?props[]=className:react!../images/svg/gatsby.svg";
import sorelleHome from "../images/portfolio/sorelle-gallery-home.jpg";
import grdnHome from "../images/portfolio/grdn-home.png";
import CozyblueHome from "../images/portfolio/cozyblue-home.png";
import BabybeauHome from "../images/portfolio/babybeau-home.png";
import AGierHome from "../images/portfolio/agier-home.png";

const PortfolioItems = () => (
  <div className="portfolio-items">
  <div className="item">
    <Link className="image-container image" to="/a-gier-design?secret=portfolio2020">
      <img src={ AGierHome } />
    </Link>
    <div className="pi-heading">
      <div className="name">
        <h2>
          <Link to="/a-gier-design?secret=portfolio2020">
            A Gier Design
          </Link>
        </h2>
      </div>
      <div className="technology">
        <ShopifyLogo alt="Shopify" />
      </div>
    </div>
    <div className="description">
      <p>
        Personalized wedding gift art featuring vintage maps and personalized locations and text.
      </p>
    </div>
      <div className="link">
        <a href="https://agierdesignshop.com/" target="_blank" >
          Website <ExternalIcon />
        </a>
      </div>
    </div>

  <div className="item">
    <Link className="image-container image" to="/baby-beau-belle?secret=portfolio2020">
      <img src={ BabybeauHome } />
    </Link>
    <div className="pi-heading">
      <div className="name">
        <h2>
          <Link to="/baby-beau-belle?secret=portfolio2020">
            Baby Beau &amp; Belle
          </Link>
        </h2>
      </div>
      <div className="technology">
        <ShopifyLogo alt="Shopify" />
      </div>
    </div>
    <div className="description">
      <p>
        Baby Beau &amp; Belle has specialized in in creating one-of-a-kind designs for babies and toddlers for over 30 years.
      </p>
    </div>
      <div className="link">
        <a href="https://babybeauandbelle.com/" target="_blank" >
          Website <ExternalIcon />
        </a>
      </div>
    </div>

  <div className="item">
    <Link className="image-container image" to="/grdn-brooklyn?secret=portfolio2020">
      <img src={ grdnHome } />
    </Link>
    <div className="pi-heading">
      <div className="name">
        <h2>
          <Link to="/grdn-brooklyn?secret=portfolio2020">
            GRDN Brooklyn
          </Link>
        </h2>
      </div>
      <div className="technology">
        <ShopifyLogo alt="Shopify" />
      </div>
    </div>
    <div className="description">
      <p>
        Plants, flowers, herbs and home goods store in Brooklyn, NY. Bouquets and arrangements delivered to NYC. Perennials and native plant gardens Fermob furniture.
      </p>
    </div>
      <div className="link">
        <a href="https://grdnbklyn.com/" target="_blank" >
          Website <ExternalIcon />
        </a>
      </div>
    </div>

  <div className="item">
    <Link className="image-container image" to="/cozyblue-handmade?secret=portfolio2020">
      <img src={ CozyblueHome } />
    </Link>
    <div className="pi-heading">
      <div className="name">
        <h2>
          <Link to="/cozyblue-handmade?secret=portfolio2020">
            Cozyblue Handmade
          </Link>
        </h2>
      </div>
      <div className="technology">
        <ShopifyLogo alt="Shopify" />
      </div>
    </div>
    <div className="description">
      <p>
      cozyblue helps you start one stitch at a time by giving you the tools, encouragement, and inspiration you need to create beautiful (and totally do-able!) hand embroidery.
      </p>
    </div>
      <div className="link">
        <a href="https://www.cozybluehandmade.com" target="_blank" >
          Website <ExternalIcon />
        </a>
      </div>
    </div>

    <div className="item">
      <Link className="image-container image" to="/sorelle-gallery?secret=portfolio2020">
        <img src={ sorelleHome } alt="Placeholder" />
      </Link>
      <div className="pi-heading">
        <div className="name">
          <h2>
            <Link to="/sorelle-gallery?secret=portfolio2020">
              Sorelle Gallery
            </Link>
          </h2>
        </div>
        <div className="technology">
          <ShopifyLogo alt="Shopify" />
        </div>
      </div>
      <div className="description">
        <p>
          Sorelle Gallery Fine Art specializes in a wide variety of contemporary, modern and traditional art from over 40 established artists.
        </p>
      </div>
        <div className="link">
          <a href="https://sorellegallery.com/" target="_blank" >
            Website <ExternalIcon />
          </a>
        </div>
      </div>

    {/*
    <div className="item">
      <Link className="image-container image" to="/">
        <img src={ Placeholder } alt="Placeholder" />
      </Link>
      <div className="pi-heading">
        <div className="name">
          <h2>
            <Link to="/">
              Iran Mateu
            </Link>
          </h2>
        </div>
        <div className="technology">
          <GatsbyLogo alt="Gatsby" />
        </div>
      </div>
      <div className="description">
        <p>
          Personal portfolio website showcasing my latest work.
        </p>
      </div>
        <div className="link">
          <Link to="/">
            Website <ExternalIcon />
          </Link>
        </div>
    </div>
    */}
  </div>
)

export default PortfolioItems